// NotFound.js
import React from "react";
import {Link} from "react-router-dom";
import "../css/Whoops404.css"; // Assuming you will create a CSS file for styling
import Navbar from "../components/inc/Navbar";

import { useEffect } from "react";

const Whoops404 = () => {

    useEffect(() => {
        document.title = "Whoops404 - jobsfair.info";
      }, []);


    return (
        <div>
            <Navbar />
            <div className="not-found">
                <div className="not-found-body">
                    <h1>404</h1>
                    <h2>Page Not Found</h2>
                    <p>
                        Sorry, the page you are looking for does not exist.<br></br>
                        Sorry, <strong>Some pages are under construction!</strong>.
                    </p>
                    <Link to="/">Go to Home</Link>
                </div>
            </div>
        </div>
    );
};

export default Whoops404;
