import React, { useState } from 'react';
import Header from '../Header';
import SidebarMenu from '../SidebarMenu';
import '../css1/AddCompany.css';

// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { CKEditor } from '@ckeditor/ckeditor5-react';


function AddCompany() {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    contact_email: '',
    contact_phone: '',
    website: '',
    description: '',
    established: ''
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;

     // or Using separate variable assignment
  // const name = e.target.name;
  // const value = e.target.value; 

    setFormData((prevData) => ({...prevData, [name]: value}));
  };


      // Define focus and blur handlers
      const handleFocus = (event) => {
        event.target.style.borderColor = '#007BFF'; // Blue border on focus
        event.target.style.boxShadow = '0 0 8px rgba(0, 123, 255, 0.5)'; // Subtle blue glow
        event.target.style.transform = 'scale(1.02)'; // Slightly scale up on focus
      };
  
      const handleBlur = (event) => {
        event.target.style.borderColor = '#ccc'; // Gray border on blur
        event.target.style.boxShadow = '0 0 4px rgba(0, 0, 0, 0.2)'; // Subtle shadow on blur
        event.target.style.transform = 'scale(1)'; // Reset scale to normal
      };
  
        // Define the styles for textareas
    const textareaStyle = {
      width: '100%',
      height: '250px',
      fontSize: '16px',
      padding: '12px',
      borderRadius: '8px',
      border: '2px solid #ccc',  // Slightly thicker border
      outline: 'none',
      resize: 'vertical',
      backgroundColor: '#f9f9f9',
      color: '#333',
      transition: 'border-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease', // Smooth transitions
    };
  
    // Define the styles for textareas
    const forOtherStyle = {
  
            fontSize: '16px',
            padding: '12px',
            borderRadius: '8px',
            border: '2px solid #ccc',  // Slightly thicker border
            outline: 'none',
            resize: 'vertical',
            backgroundColor: '#f9f9f9',
            color: '#333',
            transition: 'border-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease', // Smooth transitions
          };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Example validation logic
    if (!formData.name) {
      setError('Company Name is required');
      return;
    }

    // Reset error if no issues
    setError(null);

    // Placeholder for form submission logic
    // console.log('Form data:', formData);
    //alert('Form submitted (server code not implemented)');

    try {

      // For Server API
       //const response = await fetch(`http://localhost/web/react/www.jobsfair.info/api/api/add_new_company.php`, {
     
      const response = await fetch(`https://jobsfair.info/api/api/add_new_company.php`, {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        
      });

      const result = await response.json();
      //  console.log("result.success: "+result.success);
      // console.log("result.message: "+result.message);

      if (result.success) {
        setSuccess('Company added successfully!');
      }else {
        setError(result.message || 'An error occurred');
        throw new Error("Failed to add Company");
      }

    }catch (error) {
      alert( "Error submitting Comapny: Company probably already exists.");
      setError('An error occurred while submitting the form.');
      }

  };

  return (
    <div>
      <Header />
      <div className="company-container">
        <SidebarMenu />
        <div className="add-company-container">
          <h1>Add New Company</h1>
          <div className='session-message'>
                {error && <p className="error-message">{error}</p>}
                {success && <p className="success-message">{success}</p>}
          </div>
 
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">
              <strong>Company Name:</strong>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={forOtherStyle}
                required
              />
            </label>
            <label htmlFor="address">
              <strong>Address:</strong>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={forOtherStyle}
              />
            </label>
            <label htmlFor="contact_email">
              <strong>Contact Email:</strong>
              <input
                type="email"
                id="contact_email"
                name="contact_email"
                value={formData.contact_email}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={forOtherStyle}
              />
            </label>
            <label htmlFor="contact_phone">
              <strong>Contact Phone:</strong>
              <input
                type="text"
                id="contact_phone"
                name="contact_phone"
                value={formData.contact_phone}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={forOtherStyle}
              />
            </label>
            <label htmlFor="website">
              <strong>Website:</strong>
              <input
                type="text"
                id="website"
                name="website"
                value={formData.website}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={forOtherStyle}
              />
            </label>
            <label htmlFor="description">
              <strong>Description:</strong>

              {/* <CKEditor
                editor={ClassicEditor}
                name="description"
                data={formData.description || ""}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setFormData((prevData) => ({ ...prevData, description: data }));
                }}
                config={{
                  height: "500px"  // Adjust this value as needed
                }}
              /> */}

              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={textareaStyle}
              />
            </label>
            <label htmlFor="established">
              <strong>Established Date:</strong>
              <input
                type="date"
                id="established"
                name="established"
                value={formData.established}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={forOtherStyle}
              />
            </label>
            <button type="submit" className="submit-btn">Add Company</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCompany;
