import React, { useState } from "react";
import "./css1/Header.css"; // Assuming you have a CSS file for styling
import { useAuth } from "./auth/AuthContext";
import bedasa from "./user-profile/bedasa.JPG"; // Adjust the path as needed

const Header = () => {
  const { isAuthenticated, currentUser, logout } = useAuth();
  const [dropdownVisible, setDropdownVisible] = useState(false); // State to manage dropdown visibility

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogout = () => {
    logout(); // Trigger the logout function
  };

  if (!isAuthenticated) {
    // Optionally redirect to login or show a message
    return <p>Please log in to access this page.</p>;
  }

  return (
    <header className="admin-header">
      <h1 className="dashboard-title">Admin Dashboard</h1>
      <div className="user-profile">
        <span className="user-name">
          Welcome, {currentUser?.name || "Admin"}
        </span>
        <img
          src={bedasa}
          alt="User Profile"
          className="profile-pic"
          onClick={toggleDropdown} // Toggle dropdown on click
        />
        {dropdownVisible && (
          <div className="profile-dropdown">
            <ul>
              <li>Profile</li>
              <li>Settings</li>
              <li onClick={handleLogout}>Logout</li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
