import React, { useState, useEffect } from 'react';
import Header from '../Header';
import SidebarMenu from '../SidebarMenu';
import '../css1/AddCompany.css';
import { useParams } from "react-router-dom";

import { useNavigate } from 'react-router-dom'; // Import useNavigate
 

function EditCompany() {
  const { companyId } = useParams();
  const  navigate = useNavigate()

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    contact_email: '',
    contact_phone: '',
    website: '',
    description: '',
    established: ''
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {

           // For Server API
        const response = await fetch(`https://jobsfair.info/api/api/get_company_item.php?companyId=${companyId}`);

        //const response = await fetch(`http://localhost/web/react/www.jobsfair.info/api/api/get_company_item.php?companyId=${companyId}`);
        if (!response.ok) {
          throw new Error("Failed to fetch company data");
        }
        const data = await response.json();
        if (data) {
          setFormData(data);
          //console.log("Company data:", data);
        } else {
          setError(data.message || 'Failed to load company data.');
        }
      } catch (error) {
        setError('An error occurred while fetching company data: ' + error.message);
      }
    };

    fetchCompanyData();
  }, [companyId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFocus = (event) => {
    event.target.style.borderColor = '#007BFF';
    event.target.style.boxShadow = '0 0 8px rgba(0, 123, 255, 0.5)';
    event.target.style.transform = 'scale(1.02)';
  };

  const handleBlur = (event) => {
    event.target.style.borderColor = '#ccc';
    event.target.style.boxShadow = '0 0 4px rgba(0, 0, 0, 0.2)';
    event.target.style.transform = 'scale(1)';
  };

  const textareaStyle = {
    width: '100%',
    height: '250px',
    fontSize: '16px',
    padding: '12px',
    borderRadius: '8px',
    border: '2px solid #ccc',
    outline: 'none',
    resize: 'vertical',
    backgroundColor: '#f9f9f9',
    color: '#333',
    transition: 'border-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease',
  };

  const inputStyle = {
    fontSize: '16px',
    padding: '12px',
    borderRadius: '8px',
    border: '2px solid #ccc',
    outline: 'none',
    backgroundColor: '#f9f9f9',
    color: '#333',
    transition: 'border-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease',
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.name) {
      setError('Company Name is required');
      return;
    }

    setError(null);

    try {
            // For Server API
         const response = await fetch(`https://jobsfair.info/api/api/update_company.php`, {

      //const response = await fetch(`http://localhost/web/react/www.jobsfair.info/api/api/update_company.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          companyId
        }),
      });

      const result = await response.json();

      if (result.success) {
        alert("Company updated successfully!");
        setSuccess('Company updated successfully!');

          // Navigate to the jobs page
          navigate('/back/companies'); // Replace '/jobs' with the actual path to your jobs page

      } else {
        setError(result.message || 'An error occurred');
        alert("Failed to update Company!", result.message);
        throw new Error(result.message || "Failed to update Company");
      }
    } catch (error) {
      setError('An error occurred while submitting the form.');
      alert("An error occurred while submitting the form!");
    }
  };

  return (
    <div>
      <Header />
      <div className="company-container">
        <SidebarMenu />
        <div className="add-company-container">
          <h1>Edit Company {companyId}</h1>
          <div className='session-message'>
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}
          </div>

          <form onSubmit={handleSubmit}>
            <label htmlFor="name">
              <strong>Company Name:</strong>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={inputStyle}
                required
              />
            </label>
            <label htmlFor="address">
              <strong>Address:</strong>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={inputStyle}
              />
            </label>
            <label htmlFor="contact_email">
              <strong>Contact Email:</strong>
              <input
                type="email"
                id="contact_email"
                name="contact_email"
                value={formData.contact_email}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={inputStyle}
              />
            </label>
            <label htmlFor="contact_phone">
              <strong>Contact Phone:</strong>
              <input
                type="text"
                id="contact_phone"
                name="contact_phone"
                value={formData.contact_phone}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={inputStyle}
              />
            </label>
            <label htmlFor="website">
              <strong>Website:</strong>
              <input
                type="text"
                id="website"
                name="website"
                value={formData.website}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={inputStyle}
              />
            </label>
            <label htmlFor="description">
              <strong>Description:</strong>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={textareaStyle}
              />
            </label>
            <label htmlFor="established">
              <strong>Established Date:</strong>
              <input
                type="date"
                id="established"
                name="established"
                value={formData.established}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={inputStyle}
              />
            </label>
            <button type="submit" className="submit-btn">Update Company</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditCompany;
