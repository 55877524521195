import React, { useState, useEffect } from "react";
import SidebarMenu from "../SidebarMenu";
import Header from "../Header";
import "../css1/CompaniesList.css";
import { Link } from "react-router-dom";



function CompaniesList() {
  const [status, setStatus] = useState("");
  const [companyList, setCompanyList] = useState([]); // List of companies
  const [error, setError] = useState(""); // Error handling

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        setStatus("Loading...");

        
        //const response = await fetch(`http://localhost/web/react/www.jobsfair.info/api/api/list_companies.php`); // For local testing

          // For Server API
         const response = await fetch(`https://jobsfair.info/api/api/list_companies.php`);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        //console.log("Fetched Data:", data);

        if (Array.isArray(data)) {
          setCompanyList(data); // Set company list from fetched data
          setStatus(""); // Clear status on successful data fetch
        } else {
          throw new Error("Data is not in the expected format");
        }
      } catch (error) {
        if (error.name === 'TypeError') {
          setError('Connection to API is refused');
        } else {
          setError('An error occurred while fetching the data');
        }
        setStatus(""); // Clear status on error
        //console.error("Error fetching company details:", error);
      }
    };

    fetchCompanies();
  }, []);

 

  return (
    <div className="companies-list-page">

      <Header />

      <div className="container-page">
        
        <SidebarMenu />

        <div className="list-of-companies">
          <h1>Companies</h1>

          <button className="add-company-btn" >
          <Link to="/back/add-company" >
            Add Company
          </Link>
          </button>

          {status && <p className="status-message">{status}</p>}
          {error && <p className="error-message">{error}</p>}

          <div className="table-container">
          {companyList.length > 0 ? (
            <table className="companies-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Address</th>
                  <th>Contact Email</th>
                  <th>Contact Phone</th>
                  <th>Website</th>
                  <th>Description</th>
                  <th>Established</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {companyList.map((company) => (
                  <tr key={company.id}>
                    <td>{company.id}</td>
                    <td>{company.name}</td>
                    <td>{company.address}</td>
                    <td>{company.contact_email}</td>
                    <td>{company.contact_phone}</td>
                    <td>{company.website}</td>
                    <td>{company.description}</td>
                    <td>{company.established ? new Date(company.established).toLocaleDateString() : "N/A"}</td>
                    <td className="actions-cell">
                    <Link to={`/back/company/edit-company/${company.id}`} className="edit-link">Edit</Link>
                      {' | '}
                      <Link to={`/back/company/delete-company/${company.id}`} className="delete-link">Delete</Link>
                    </td>
                      
                      
                   
                  </tr>
                ))}
              </tbody>
            </table>
            
          ) : (
            <p>No companies available</p>
          )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompaniesList;
