import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../Header";
import SidebarMenu from "../SidebarMenu";
import "../css1/DeleteJobItem.css";

const DeleteJobItem = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [job, setJob] = useState(null); // State to store job information

  // Fetch job information on component mount
  useEffect(() => {
    const fetchJob = async () => {
      try {

                       // For Server API
        // const response = await fetch(`https://jobsfair.info/api/api/get_job_item.php?jobId=${jobId}`);
        

        const response = await fetch(`http://localhost/web/react/www.jobsfair.info/api/api/get_job_item.php?jobId=${jobId}`);

        if (!response.ok) {
          throw new Error("Failed to fetch job details");
        }

        const data = await response.json();
        setJob(data); // Set job data to state
      } catch (err) {
        setError(err.message);
      }
    };

    fetchJob();
  }, [jobId]);

  const handleDelete = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete this job?");

    if (confirmDelete) {
      try {

        
        // For Server API
         const response = await fetch(`https://jobsfair.info/api/api/delete_job_item.php?jobId=${jobId}`, {

        // const response = await fetch(`http://localhost/web/react/www.jobsfair.info/api/api/delete_job_item.php?jobId=${jobId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ jobId }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to delete job");
        }

        alert("Job deleted successfully!");
        setSuccess("Job deleted successfully!");
        navigate('/back/jobs'); // Navigate to the jobs page after deletion

      } catch (err) {
        setError(err.message);
      }
    } else {
      setError("Job deletion canceled.");
    }
  };

  return (
    <div className="jobs-delete-item-page">
      <Header />
      <div className="jobs-delete-container">
        <SidebarMenu />
        <div className="jobs-item-content">
          <h2>Delete Job ID: {jobId}</h2>
          <div className="session-message">
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}
          </div>
          {job ? (
            <div>
              <h3>Job Title: {job.title}</h3>
              <p>Job ID: {job.job_id}</p>
              <p>Company ID: {job.company_id}</p>
         
              <p>Location: {job.location}</p>
              <p>Deadline: {job.deadline}</p>
              <p>Description: {job.description}</p>
              <button onClick={handleDelete} className="delete-btn">
                Confirm Delete
              </button>
            </div>
          ) : (
            <p>Loading job information...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteJobItem;
