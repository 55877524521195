import React from "react";

import JobsList from "../jobs/JobsList";
// import "../../css/details.css";
// import "../../css/jobs_list_display.css";
import SearchForm from "./SearchForm";
import Navbar from "../../components/inc/Navbar";

import { useEffect } from "react";
// import Pagination from "../../paginations/Pagination";

function Landing() {
  useEffect(() => {
    document.title = "Home - jobsfair.info";
  }, []);

  return (
    <div>
      <Navbar></Navbar>

      <SearchForm />
      <hr></hr>

      <JobsList />
    </div>
  );
}

export default Landing;
