import React from "react";
import "../../css/Loading.css"; // Renamed the CSS file to Loading.css for clarity

const Loading = () => {
  return (
    <div className="loading-content">
      <div className="loading-body">
        <div className="spinner" aria-label="Loading">
          <p>Loading...</p>
        </div>
      </div>
    </div>
  );
};

export default Loading;
