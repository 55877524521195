import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Login from "./pages/auths/Login";

import Whoops404 from "./pages/Whoops404";
import JobDetails from "./pages/jobs/JobDetails";
import AboutUs from "./pages/landing/AboutUs";
import FeedbackForm from "./back/pages/FeedbackForm";
import Dashboard from "./back/Dashboard";
import SidebarMenu from "./back/SidebarMenu";
import Jobs from "./back/jobs/Jobs";
import AddJob from "./back/jobs/AddJob";
import CompaniesList from "./back/company/CompaniesList";
import AddCompany from "./back/company/AddCompany";
import LoginUser from "./back/auth/LoginUser";
import ProtectedRoute from "./back/auth/ProtectedRoute";
import JobEditItem from "./back/jobs/JobEditItem";
import DeleteJobItem from "./back/jobs/DeleteJobItem";
import EditCompany from "./back/company/EditCompany";
import DeleteCompanyItem from "./back/company/DeleteCompanyItem";
import { AuthProvider } from "./back/auth/AuthContext";
import ScholarshipsList from "./pages/scholarships/ScholarshipsList";
// import ScholarshipCard from "./pages/scholarships/ScholarshipCard";
import ScholarshipDetails from "./pages/scholarships/ScholarshipDetails";
import AddScholarship from "./back/pages/scholarships/AddScholarship";
import UserRegistrations from "./back/pages/user/UserRegistrations";

const router = createBrowserRouter([
  { path: "/", element: <App /> },
  { path: "*", element: <Whoops404 /> },
  { path: "/pages/auths/login", element: <Login /> },

  { path: "/pages/landing/aboutus", element: <AboutUs /> },
  { path: "/pages/jobs/:id/details", element: <JobDetails /> },
  { path: "/pages/jobs/jobs", element: <Jobs /> }, // Updated route

  //   scholarships
  { path: "/pages/scholarships", element: <ScholarshipsList /> }, // Updated route
  { path: "/pages/scholarships/:id/details", element: <ScholarshipDetails /> }, // Updated route

  //   back scholar
  { path: "/back/pages/scholarships/add", element: <AddScholarship /> }, // Updated route

  { path: "*", element: <Whoops404 /> },

  // Protected routes
  {
    path: "/back/dashboard",
    element: <ProtectedRoute element={<Dashboard />} />,
  },
  {
    path: "/back/sidebar",
    element: <ProtectedRoute element={<SidebarMenu />} />,
  },
  {
    path: "/back/jobs",
    element: <ProtectedRoute element={<Jobs />} />,
  },
  {
    path: "/back/jobs/add-jobs",
    element: <ProtectedRoute element={<AddJob />} />,
  },
  {
    path: "/back/jobs/edit-job/:jobId",
    element: <ProtectedRoute element={<JobEditItem />} />,
  },
  {
    path: "/back/jobs/delete-job/:jobId",
    element: <ProtectedRoute element={<DeleteJobItem />} />,
  },
  {
    path: "/back/companies",
    element: <ProtectedRoute element={<CompaniesList />} />,
  },
  {
    path: "/back/add-company",
    element: <ProtectedRoute element={<AddCompany />} />,
  },
  {
    path: "/back/company/edit-company/:companyId",
    element: <ProtectedRoute element={<EditCompany />} />,
  },
  {
    path: "/back/company/delete-company/:companyId",
    element: <ProtectedRoute element={<DeleteCompanyItem />} />,
  },
  {
    path: "/back/users/add-user",
    element: <ProtectedRoute element={<UserRegistrations />} />,
  },

  { path: "/back/auth/login", element: <LoginUser /> },
  { path: "/back/pages/feedback", element: <FeedbackForm /> },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);
