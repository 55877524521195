import React from "react";
import "../../css/Error.css";

function Error({ message, retryHandler, title = "Something Went Wrong" }) {
  return (
    <main className="error-container" role="main">
      {/* <Navbar /> */}
      <section className="error-content">
        <div className="error-body">
          <h1 className="error-title">{title}</h1>
          <p>{message} Please try again later.</p>
          {retryHandler && (
            <button
              className="error-retry-button"
              onClick={retryHandler}
              aria-label="Retry action"
            >
              Retry
            </button>
          )}
        </div>
      </section>
    </main>
  );
}

export default Error;
