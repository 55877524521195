import { useState } from "react";
import Header from "../../Header";
import SidebarMenu from "../../SidebarMenu";
import "../../css1/UsersRegistrations.css"; // Import the CSS file

function UserRegistrations() {
  const [user, setUser] = useState({
    email: "",
    name: "",
    password: "",
    confirmPassword: "",
    status: "active",
    role: "user",
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  function handleChange(event) {
    const { name, value } = event.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  }

  const handleFocus = (e) => {
    e.target.style.borderColor = "#007bff"; // Change border color on focus
    e.target.style.boxShadow = "0 0 3px rgba(0, 123, 255, 0.5)"; // Optional: Add a subtle shadow
  };

  const handleBlur = (e) => {
    e.target.style.borderColor = "#ddd"; // Revert border color on blur
    e.target.style.boxShadow = "none"; // Remove shadow on blur
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Add form submission logic here
    console.log("Form submitted with values:", user);

    // Check if passwords match
    if (user.password !== user.confirmPassword) {
      setError("Passwords do not match"); // Set error if passwords don't match
      return;
    }

    try {
      // const response = await fetch(
      //   `http://localhost/web/react/www.jobsfair.info/api/api/addNewUser.php`,
      const response = await fetch(
        `https://jobsfair.info/api/api/api/addNewUser.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );
      if (!response.ok) {
        setError("Network response was not ok"); // Handle non-2xx responses
      }
      const data = await response.json();
      if (data.success) {
        setSuccess("User registered successfully!");
        setError(null); // Clear any existing error messages
      } else {
        setError(data.message); // Show any server-side error message
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setError("An error occurred during submission"); // Set error message
      // Handle errors (e.g., show an error message to the user)
    }
  }; //handleSubmit

  return (
    <div>
      <Header />
      <div className="users-container">
        <SidebarMenu />
        <div className="Users-page">
          <h1>Add New User</h1>
          <div className="session-message">
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}
          </div>

          <div
            className="registration-card"
            tabIndex="1"
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={user.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={user.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={user.password}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm Password:</label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={user.confirmPassword}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="status">Status:</label>
                <select
                  id="status"
                  name="status"
                  value={user.status}
                  onChange={handleChange}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="role">Role:</label>
                <select
                  id="role"
                  name="role"
                  value={user.role}
                  onChange={handleChange}
                >
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                  <option value="guest">Guest</option>
                </select>
              </div>

              <button type="submit">Register</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserRegistrations;
