import React from "react";
import "../../css/AboutUs.css"; // Import the CSS file
import Navbar from "../../components/inc/Navbar";
import { useEffect } from "react";

const AboutUs = () => {
  useEffect(() => {
    document.title = "About Us - jobsfair.info";
  }, []);

  return (
    <div>
      <Navbar />
      <div className="about-us">
        <div className="left-side">
          {/* You can add content or images here if needed */}
        </div>
        <div className="center-side">
          <div className="about-us-content mycard">
            <h2>About Us</h2>
            <p>
              Welcome to{" "}
              <a href="https://jobsfair.info" rel="noreferrer" target="_blank">
                https://jobsfair.info
              </a>{" "}
              Jobs! We are dedicated to connecting talented individuals with the
              best opportunities in the industry. Our mission is to empower job
              seekers and support businesses in finding the right fit for their
              teams.
            </p>
          </div>

          <div className="about-us-mission mycard">
            <h2>Our Mission</h2>
            <p>
              Our mission is to create a platform where opportunities meet
              talent. We aim to provide resources, training, and support to help
              our users achieve their career goals and help businesses build
              stronger teams.
            </p>
          </div>

          <div className="about-us-team mycard">
            <h2>Meet the Team</h2>
            <div className="team-members">
              <div className="team-member">
                <img src="path-to-team-member-photo.jpg" alt="Bedasa W" />
                <h3>B W & E D</h3>
                <p>CEO & Founder</p>
              </div>
              <div className="team-member">
                <img src="path-to-team-member-photo.jpg" alt="Team 1" />
                <h3>Team 1</h3>
                <p>Chief Technology Officer</p>
              </div>
              <div className="team-member">
                <img src="path-to-team-member-photo.jpg" alt="Team 2" />
                <h3>Team 2</h3>
                <p>Head of Marketing</p>
              </div>
              {/* Add more team members as needed */}
            </div>
          </div>

          <div className="about-us-contact mycard">
            <h2>Contact Us</h2>
            <p>
              We would love to hear from you! Whether you have questions,
              feedback, or just want to connect, feel free to reach out to us
              through our contact form or social media channels.
            </p>
            <div className="button-container">
              <a href="/contact" className="btn-apply">
                Get in Touch
              </a>
            </div>
          </div>
        </div>
        <div className="right-side">
          {/* You can add content or images here if needed */}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
