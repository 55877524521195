import React, { useState, useEffect } from "react";
import ScholarshipCard from "./ScholarshipCard";
import Navbar from "../../components/inc/Navbar";
// import LeftSidePane from "../../sidebar/LeftSidePane";
// import RightSidePane from "../../sidebar/RightSidePane";

import "../../css/ScholarshipsList.css";

const ScholarshipsList = () => {
  const [scholarships, setScholarships] = useState([]);
  const [status, setStatus] = useState("Loading ...");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const fetchScholarshipsData = async () => {
      try {
        // const response = await fetch(
        //   `http://localhost/web/react/www.jobsfair.info/api/api/scholarships/get_all_scholarships.php`
        // ); // For local testing

        const response = await fetch(
          `https://jobsfair.info/api/api/scholarships/get_all_scholarships.php`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const result = await response.json();

        if (result.success) {
          setScholarships(result.data);
          setStatus("Loaded");
          setSuccess("Scholarships loaded successfully!");
        } else {
          setStatus("No data found");
          setSuccess(null);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(`Error loading data: ${error.message}`);
        setStatus("Error loading data");
        setSuccess(null);
      }
    };

    fetchScholarshipsData();
  }, []);

  return (
    <div className="main-scholarship">
      <Navbar />
      <div className="container-scholarship">
        <div className="side-panel1">
          {/* <h4>Refine the List with Filters</h4> */}
          {/* <LeftSidePane /> */}
        </div>

        <div className="body-scholarship">
          {/* <div className="session-message">
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}
          </div> */}

          <div className="scholarships-list">
            <h1>Scholarships</h1>
            {scholarships.length > 0 ? (
              scholarships.map((scholarship) => (
                <ScholarshipCard
                  key={scholarship.id}
                  scholarship={scholarship}
                />
              ))
            ) : (
              <p>No scholarships available</p>
            )}
          </div>
        </div>
        <div className="side-panel2">
          {/* <h4>Refine the List with Filters</h4> */}
          {/* <RightSidePane /> */}
        </div>
      </div>
    </div>
  );
};

export default ScholarshipsList;
