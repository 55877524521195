import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram, faFacebook, faTwitter, faLinkedin, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

const SocialMediaIcons = () => {
    return (
        <div>
            <strong>Social Media:</strong>
            <ul style={{ display: "flex", listStyleType: "none", padding: 0 }}>
                <li style={{ marginRight: "10px" }}>
                    <a href="https://t.me/dofaTube" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTelegram} size="2x" />
                    </a>
                </li>
                <li style={{ marginRight: "10px" }}>
                    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebook} size="2x" />
                    </a>
                </li>
                <li style={{ marginRight: "10px" }}>
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTwitter} size="2x" />
                    </a>
                </li>
                <li style={{ marginRight: "10px" }}>
                    <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} size="2x" />
                    </a>
                </li>
                <li style={{ marginRight: "10px" }}>
                    <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} size="2x" />
                    </a>
                </li>
                <li style={{ marginRight: "10px" }}>
                    <a href="https://youtube.com/@dofatube" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faYoutube} size="2x" />
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default SocialMediaIcons;
