import React, { useState } from 'react';
import "../css1//FeedbackForm.css";
import Navbar from "../../components/inc/Navbar";


// import ClassicEditor from '../../ckeditor5/ckeditor5';

// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { CKEditor } from '@ckeditor/ckeditor5-react';

// npm i @ckeditor/ckeditor5-react

const FeedbackForm = () => {
  const [feedback, setFeedback] = useState({});
  const [status, setStatus] = useState("");



  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatus("");

    try {
       const response = await fetch( `https://jobsfair.info/api/api/submit_feedback.php`,
      
     // const response = await fetch( `http://localhost/web/react/jobsfair.info/api/api/submit_feedback.php`,
        
       {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(feedback),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

     const data = await response.json();
     setStatus(data.message || "Feedback submitted successfully!");

      // Display Thank You alert
      alert("Thank you for your feedback!");

      // Clear the form by resetting the feedback state
     setFeedback({});
       // Log the feedback data to the console
       console.log("Feedback data:", feedback);
    } catch (error) {
      console.error("Error:", error);
      setStatus("An error occurred. Please try again.");
    }

  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFeedback((values) => ({ ...values, [name]: value }));
  };

  return (
    <div>
      <Navbar />
      <div className="feedback-container">
        <h2>We Value Your Feedback</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              name="name"
              value={feedback.name || ""}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={feedback.email || ""}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Message</label>
            <textarea
              id="editor1"
              name="message"
              // ref={editorRef}
              value={feedback.message || ""}
              onChange={handleChange}
              required
            />
          </div>
          {/* <div className="form-group">
            <label>Message</label>
            <CKEditor
                editor={ClassicEditor}
                 name="message"
                data={feedback.message || ""}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setFeedback({ ...feedback, message: data });
                }}
            />
          </div> */}
          <button type="submit" className="submit-button">
            Submit
          </button>
          {status && <p className="status-message">{status}</p>}
        </form>
      </div>
    </div>
  );
};

export default FeedbackForm;
