import React from "react";
import { Link } from "react-router-dom";
import "../../css/ScholarshipCard.css"; // Import the CSS file

const ScholarshipCard = ({ scholarship }) => {
  return (
    <div className="scholarship-card">
      <h3>{scholarship.title}</h3>
      <p>
        <strong>Institution: </strong> {scholarship.university}
      </p>
      <p>
        <strong>Institution: </strong>
        {scholarship.institution}
      </p>
      <p>
        <strong>Study In: </strong>
        {scholarship.study_in}
      </p>

      <Link to={`/pages/scholarships/${scholarship.id}/details`}>
        Click here for Deadlines and View Details
      </Link>
    </div>
  );
};

export default ScholarshipCard;
