import React, { useState, useEffect } from "react";
import JobCard from "./JobCard";
import Loading from "../jobs/Loading";
import Error from "../jobs/Error";
// import "../css/Pagination.css";
// import "../../css/details.css";
import "../../css/jobs_list_display.css";
import Pagination from "../../paginations/Pagination";

function JobsList() {
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const companiesPerPage = 3;

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        // For Local
        // const response = await fetch(
        //   `http://localhost/web/react/www.jobsfair.info/api/api/lists_of_jobs.php`
        // );

        const response = await fetch(
          `https://jobsfair.info/api/api/lists_of_jobs.php`
        );

        if (!response.ok) {
          setError("Something went wrong!");
          // throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        if (data.status) {
          setCompanies(data.data || []);
        } else {
          // throw new Error("Data is not in the expected format");
          setError("Something went wrong!");
        }
      } catch (error) {
        setError("Something went wrong!");
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  // Pagination variables
  const endIndex = currentPage * companiesPerPage;
  const startIndex = endIndex - companiesPerPage;
  const currentCompanies = companies.slice(startIndex, endIndex);

  // Render jobs for a company
  function renderJobs(company) {
    if (company.jobs && company.jobs.length > 0) {
      return company.jobs.map((job) => (
        <JobCard key={job.job_id} jobList={job} />
      ));
    } else {
      return <p>No jobs available</p>;
    }
  }

  // Render company list
  function renderCompanies(companies) {
    return companies.length > 0 ? (
      companies.map((company) => (
        <div className="jobs-main-container mycard" key={company.company_id}>
          <h2>Posted by: {company.company_name}</h2>
          <div className="jobs-info">{renderJobs(company)}</div>
        </div>
      ))
    ) : (
      <p>No companies available</p>
    );
  }

  const totalPages = Math.ceil(companies.length / companiesPerPage);

  if (loading) return <Loading />;
  if (error) return <Error message={error} />;

  return (
    <div>
      <div className="jobs-list-container">
        <div className="side-panel1">
          {/* <h4>Refine the List with Filters</h4> */}
          {/* <LeftSidePane /> */}
        </div>

        <div className="jobs-main-container">
          {/* <h1>Job Listings</h1> */}
          <div className="jobs-container">
            <div className="jobs-info">
              {/* {renderCompanies(currentCompanies)} */}
              {/* <div className="pagination">{renderPaginationControls()}</div> */}

              <div className="jobs-container">
                <div className="jobs-info">
                  {renderCompanies(currentCompanies)}
                </div>
              </div>

              <div>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage} // This is crucial
                />
              </div>
            </div>
          </div>
        </div>

        <div className="side-panel2">
          {/* <h1>Additional Information</h1> */}

          {/* <RightSidePane /> */}
        </div>
      </div>
    </div>
  );
}

export default JobsList;
