import React from "react";
import "../../css/login.css";
import Navbar from "../../components/inc/Navbar";
import profileIcon from "../../assets/profileIcon.png";
import SocialMediaIcons from "../../components/inc/SocialMediaIcons";
import { useEffect } from "react";

function Login() {

    useEffect(() => {
        document.title = "Login - jobsfair.info";
      }, []);

    return (
        <div>
            <Navbar />
            <div className="loginpage">
                <div className="login-main-sidebar">
                    <div className="myLoginCard">
                        <h2>Welcome Back!</h2>
                        <div>
                            <strong>Why Login?</strong>
                        </div>
                        <ul className="login-advantages">
                            <li>Be the first to receive new updates and features.</li>
                            <li>Access exclusive content and personalized recommendations.</li>
                            <li>Join our community and participate in discussions.</li>
                            <li>Receive special offers and discounts.</li>
                            <li>Track your progress and save your preferences.</li>
                        </ul>
                        <div>
                            <strong>Connect with Us</strong>
                            <p>You can reach out to us through</p>
                            <div className="socialMediaIcons-md">
                                <SocialMediaIcons />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="login-right-sidebar">
                    <div className="myLoginCard">
                        <h2>Welcome Back!</h2>
                        <form className="loginform">
                            <div className="profile-image-container">
                                <img src={profileIcon} alt="Profile" className="ProfileImage" />
                            </div>
                            <label htmlFor="username" className="Username">
                                Username
                            </label>
                            <input type="text" id="username" className="Username" placeholder="Username (optional)" />

                            <label htmlFor="email" className="Email">
                                Email
                            </label>
                            <input type="email" id="email" className="Email" placeholder="Email (required)" required />

                            <label htmlFor="interest" className="Interest">
                                Area of Interest
                            </label>
                            <select className="Interest" id="interest" name="interest" defaultValue="">
                                <option value="" disabled>
                                    Select your area of interest
                                </option>
                                <option value="software-engineering">Software Engineering</option>
                                <option value="data-science">Data Science</option>
                                <option value="web-development">Web Development</option>
                                <option value="networking">Networking</option>
                                <option value="cyber-security">Cyber Security</option>
                                {/* Add more options as needed */}
                            </select>

                            <button type="submit" className="loginbtn">Login</button>
                        </form>
                        <div className="socialMediaIcons-sm col-mds">
                            <SocialMediaIcons />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
