// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

function ProtectedRoute({ element }) {
  const { isAuthenticated } = useAuth();

  // If the user is authenticated, render the element; otherwise, navigate to the login page
  return isAuthenticated ? element : <Navigate to="/back/auth/login" />;
}

export default ProtectedRoute;
