import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../Header";
import SidebarMenu from "../SidebarMenu";
import "../css1/JobItemEdit.css";
import { useNavigate } from 'react-router-dom'; // Import useNavigate


const JobEditItem = () => {
  const  navigate = useNavigate()

  const { jobId } = useParams();
  const [formData, setFormData] = useState({
    title: '',
    company_id: '',
    description: '',
    requirement: '',
    experiences: '',
    responsibility: '',
    qualification: '',
    applyUrl: '',
    category: '',
    department: '',
    location: '',
    updatedAt: '',
    deadline: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {


        // Fetch companies
        const fetchCompanies = async () => {
            try {

              // For server
              const response = await fetch(`https://jobsfair.info/api/api/list_companies.php`);
            
             // const response = await fetch(`http://localhost/web/react/www.jobsfair.info/api/api/list_companies.php`);
              if (!response.ok) {
                throw new Error("Failed to fetch companies");
              }
              const data = await response.json();
              setCompanies(data);
            } catch (err) {
              setError(err.message);
            }
          };

    // Fetch job data using jobId when component mounts
    const fetchJobData = async () => {
      try {

          // For Server API
         const response = await fetch(`https://jobsfair.info/api/api/get_job_item.php?jobId=${jobId}`);


       // const response = await fetch(`http://localhost/web/react/www.jobsfair.info/api/api/get_job_item.php?jobId=${jobId}`);
        if (!response.ok) {
          throw new Error("Failed to fetch job data");
        }
        const data = await response.json();

        //console.log("From Server API", data);

        setFormData(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchCompanies();
    fetchJobData();
  }, [jobId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Log formData to check its content
    console.log("Submitting data:", formData);

    // Check if jobId is defined
    if (!jobId) {
        console.error("Job ID is missing");
        alert("Job ID is required");
        return;
    }

    setLoading(true); // Optionally show a loading state

    try {

       // For Server API
         const response = await fetch(`https://jobsfair.info/api/api/update_job_item.php`, {


        //const response = await fetch(`http://localhost/web/react/www.jobsfair.info/api/api/update_job_item.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...formData, jobId }), // Include jobId in the request body
        });

        if (!response.ok) {
            // Parse the response as JSON and throw an error if the status is not OK
            const errorData = await response.json();
            console.error("Error response:", errorData); // Log error details
            throw new Error(errorData.message || 'Failed to update job');
        }

        // Optionally, parse the response if needed
        const responseData = await response.json();

        console.log("Update response:", responseData); // Log the successful response

        alert("Job updated successfully!");

          // Log formData to check its content
        console.log("Updates data:", formData);

        setSuccess("Job updated successfully!");
        setFormData({
            title: '',
            company_id: '',
            description: '',
            requirement: '',
            experiences: '',
            responsibility: '',
            qualification: '',
            applyUrl: '',
            category: '',
            department: '',
            location: '',
            updatedAt: '',
            deadline: ''
        }); // Reset form data or handle the success state as needed

         // Navigate to the jobs page
         navigate('/back/jobs'); // Replace '/jobs' with the actual path to your jobs page


    } catch (err) {
        console.error("Submission error:", err); // Log error details
        alert("Oops! Something went wrong. Please try again.");
        setError(err.message);
    } finally {
        setLoading(false); // Hide the loading state
    }
};


  return (
    <div className="jobs-edit-item-page">
      <Header />
      <div className="jobs-sidebar-container">
        <SidebarMenu />
        <div className="jobs-item-content">
          <h2>Edit Job {jobId}</h2>
          <div className="session-message">
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="title">Job Title:</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="company_id">Company:</label>
                <select
                  id="company_id"
                  name="company_id"
                  value={formData.company_id}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Company</option>
                  {companies.map(company => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="description">Description:</label>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="requirement">Requirement:</label>
                <textarea
                  id="requirement"
                  name="requirement"
                  value={formData.requirement}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="experiences">Experiences:</label>
                <textarea
                  id="experiences"
                  name="experiences"
                  value={formData.experiences}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="responsibility">Responsibility:</label>
                <textarea
                  id="responsibility"
                  name="responsibility"
                  value={formData.responsibility}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="qualification">Qualification:</label>
                <textarea
                  id="qualification"
                  name="qualification"
                  value={formData.qualification}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="applyUrl">Apply URL:</label>
                <input
                  type="text"
                  id="applyUrl"
                  name="applyUrl"
                  value={formData.applyUrl}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="category">Category:</label>
                <input
                  type="text"
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="department">Department:</label>
                <input
                  type="text"
                  id="department"
                  name="department"
                  value={formData.department}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="location">Location:</label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="updatedAt">Updated Date:</label>
                <input
                  type="date"
                  id="updatedAt"
                  name="updatedAt"
                  value={formData.updatedAt}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="deadline">Deadline:</label>
                <input
                  type="date"
                  id="deadline"
                  name="deadline"
                  value={formData.deadline}
                  onChange={handleChange}
                />
              </div>
              <button type="submit" className="submit-btn">Save Changes</button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobEditItem;
