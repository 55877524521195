import React, { createContext, useState, useEffect, useContext } from "react";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); // Added loading state

  // Load authentication state from sessionStorage on initialization
  useEffect(() => {
    const storedUser = sessionStorage.getItem("currentUser");
    const storedAuthStatus = sessionStorage.getItem("isAuthenticated");
    const token = sessionStorage.getItem("token");

    if (storedUser && storedAuthStatus === "true" && token) {
      setIsAuthenticated(true);
      setCurrentUser(JSON.parse(storedUser));
    }

    setLoading(false); // Set loading to false after checking storage
  }, []);

  const login = async (email, password) => {
    if (!email || !password) {
      alert("Email and password are required.");
      return false;
    }

    try {
      //Local api
      // const response = await fetch(
      // `http://localhost/web/react/www.jobsfair.info/api/api/user_login.php`,
      const response = await fetch(
        `https://jobsfair.info/api/api/user_login.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();

      if (data.success) {
        sessionStorage.setItem("isAuthenticated", "true");
        sessionStorage.setItem("currentUser", JSON.stringify(data.user));
        sessionStorage.setItem("token", data.token);

        setIsAuthenticated(true);
        setCurrentUser(data.user);

        // console.log("Login successful. Current user:", data.user);
        alert(`Response: ${data.message}`);

        return true;
      } else {
        alert(`Response: ${data.message}`);
        return false;
      }
    } catch (error) {
      console.error("Login error:", error);
      setIsAuthenticated(false);
      setCurrentUser(null);

      sessionStorage.removeItem("isAuthenticated");
      sessionStorage.removeItem("currentUser");
      sessionStorage.removeItem("token");

      return false;
    }
  };

  const logout = () => {
    sessionStorage.removeItem("isAuthenticated");
    sessionStorage.removeItem("currentUser");
    sessionStorage.removeItem("token");

    setIsAuthenticated(false);
    setCurrentUser(null);
    console.log("User logged out.");
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, currentUser }}
    >
      {loading ? <div>Loading...</div> : children}
    </AuthContext.Provider>
  );
}
