import React, { useState } from "react";
import "./css1/Sidebar.css";
import { useAuth } from "./auth/AuthContext"; // Import useAuth hook
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate for redirection

function SidebarMenu() {
  const [isPagesOpen, setIsPagesOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isCompaniesOpen, setIsCompaniesOpen] = useState(false);
  const [isJobsOpen, setIsJobsOpen] = useState(false);
  const [isUsersOpen, setIsUsersOpen] = useState(false);

  function handleToggle(section) {
    if (section === "pages") {
      setIsPagesOpen(!isPagesOpen);
    } else if (section === "settings") {
      setIsSettingsOpen(!isSettingsOpen);
    } else if (section === "companies") {
      setIsCompaniesOpen(!isCompaniesOpen);
    } else if (section === "jobs") {
      setIsJobsOpen(!isJobsOpen);
    } else if (section === "users") {
      setIsUsersOpen(!isUsersOpen);
    }
  }

  return (
    <div>
      <div className="back-sidebar">
        <h2>Admin Menu</h2>
        <div className="back-sidebar-content">
          <div className={`back-section ${isPagesOpen ? "active" : ""}`}>
            <h3 onClick={() => handleToggle("pages")}>
              Pages {isPagesOpen ? "▼" : "▶"}
            </h3>
            {isPagesOpen && (
              <ul>
                <li>
                  <Link to="/back/dashboard">Dashboard</Link>
                </li>
              </ul>
            )}
          </div>

          <div className={`back-section ${isCompaniesOpen ? "active" : ""}`}>
            <h3 onClick={() => handleToggle("companies")}>
              Companies {isCompaniesOpen ? "▼" : "▶"}
            </h3>
            {isCompaniesOpen && (
              <ul>
                <li>
                  <Link to="/back/add-company">Add Company</Link>
                </li>
                <li>
                  <Link to="/back/companies">Manage Companies</Link>
                </li>
              </ul>
            )}
          </div>

          <div className={`back-section ${isJobsOpen ? "active" : ""}`}>
            <h3 onClick={() => handleToggle("jobs")}>
              Jobs {isJobsOpen ? "▼" : "▶"} {/* ▼ when open, ▶ when closed */}
            </h3>
            {isJobsOpen && (
              <ul>
                <li>
                  <Link to="/back/jobs/add-jobs">Add Job</Link>
                </li>
                <li>
                  <Link to="/back/jobs">Manage Jobs</Link>
                </li>
              </ul>
            )}
          </div>

          <div className={`back-section ${isUsersOpen ? "active" : ""}`}>
            <h3 onClick={() => handleToggle("users")}>
              Users {isUsersOpen ? "▼" : "▶"}
            </h3>
            {isUsersOpen && (
              <ul>
                <li>
                  <Link to="/back/users/add-user">Add User</Link>
                </li>
                <li>
                  <Link to="/back/users/">Manage Users</Link>
                </li>
              </ul>
            )}
          </div>

          <div className={`back-section ${isSettingsOpen ? "active" : ""}`}>
            <h3 onClick={() => handleToggle("settings")}>
              Settings {isSettingsOpen ? "▼" : "▶"}
            </h3>
            {isSettingsOpen && (
              <ul>
                <li>
                  <Link to="/settings">General Settings</Link>
                </li>
                <li>
                  <Link to="/profile">Profile Settings</Link>
                </li>
                <li>
                  <Link to="/security">Security</Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SidebarMenu;
