import React from "react";
import { Link } from "react-router-dom";

function JobCard({ jobList }) {
  // Destructure properties from jobList for easier access
  const {
    job_id: id, // Rename job_id to id
    title,
    updatedAt,
    deadline,
    requirement,
    description,
  } = jobList;

  return (
    <div>
      <hr className="border-custom" />
      <div className="overview-jobs">
        <p>
          {" "}
          Job Title: <strong>{title}</strong>
        </p>
        <p>Updated At: {updatedAt}</p>
        <p>Deadline: {deadline}</p>
        <p>Requirement: {requirement}</p>
        <div className="posted-updated-date"></div>
        <hr />
        <p>Description: {description}</p>
        <div className="button-detail-apply">
          <button className="btn-details">
            <Link to={`pages/jobs/${id}/details`}>See Details</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default JobCard;
