import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../jobs/Loading";
import Error from "../jobs/Error";
import Navbar from "../../components/inc/Navbar";
import Footer from "../../components/inc/Footer";
import SearchForm from "../landing/SearchForm";
import { Link } from "react-router-dom";

function JobDetails() {
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJobDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://jobsfair.info/api/api/jobs_single_details.php?id=${id}`
        );
        //const response = await fetch( `http://localhost/web/react/jobsfair.info/api/api/jobs_single_details.php?id=${id}`);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          const data = await response.json();
          // console.log(data); // Verify data received
          setJob(data);
        }
      } catch (error) {
        setError("API is not available");
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error} />;
  }

  if (!job) {
    return <Error message="Job not found" />;
  }

  return (
    <div className="job-details">
      <Navbar />
      <SearchForm />
      <hr className="border-custom no-padding-margin" />
      <div className="jobs-list-container">
        <div className="side-panel1">
          <div className="filter-options">
            {/* Additional filtering options */}
          </div>
        </div>
        <div className="jobs-main-container">
          <div className="jobs-detail-container">
            <div className="jobs-info mycard">
              <h1>Job Details</h1>
              <hr className="border-custom no-padding-margin" />
              {job.company_name && (
                <p>
                  <strong>Company Name:</strong> {job.company_name}
                </p>
              )}
              {job.title && (
                <p>
                  <strong>Job Title: </strong> {job.title}
                </p>
              )}
              {job.department && (
                <p>
                  <strong>Job Department: </strong> {job.department}
                </p>
              )}
              {job.location && (
                <p>
                  <strong>Job Location: </strong> {job.location}
                </p>
              )}
              {job.updatedAt && (
                <p>
                  <strong>Updated At:</strong> {job.updatedAt}
                </p>
              )}
              {job.deadline && (
                <p>
                  <strong>Deadline:</strong> {job.deadline}
                </p>
              )}
              {job.description && (
                <p>
                  <strong>Description: </strong> {job.description}
                </p>
              )}
              {job.requirement && (
                <p>
                  <strong>Requirement: </strong>
                </p>
              )}
              {job.requirement && (
                <ul className="job-details-list">
                  <li>{job.requirement}</li>
                </ul>
              )}
              {job.experiences && (
                <p>
                  <strong>Experiences: </strong>
                </p>
              )}
              {job.experiences && (
                <ul className="job-details-list">
                  <li>{job.experiences}</li>
                </ul>
              )}
              {job.responsibility && (
                <p>
                  <strong>Responsibility: </strong>
                </p>
              )}
              {job.responsibility && (
                <ul className="job-details-list">
                  <li>{job.responsibility}</li>
                </ul>
              )}
              {job.qualification && (
                <p>
                  <strong>Qualification: </strong>
                </p>
              )}
              {job.qualification && (
                <ul className="job-details-list">
                  <li>{job.qualification}</li>
                </ul>
              )}
              {job.applyUrl && (
                <p>
                  <strong>Apply Link: </strong>
                </p>
              )}

              {job.applyUrl && (
                <ul className="job-details-list">
                  <li>
                    <Link
                      to={job.applyUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="job-apply-link"
                    >
                      {job.applyUrl}
                    </Link>
                  </li>
                </ul>
              )}

              <div className="pagination">
                {/* {renderPaginationControls()} */}
              </div>
            </div>
          </div>
        </div>
        <div className="side-panel2">
          <div className="additional-info">
            {/* Additional information or ads can go here */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default JobDetails;
