import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../css/ScholarshipDetails.css"; // Import the CSS file
import Navbar from "../../components/inc/Navbar";

const ScholarshipDetails = () => {
  const { id } = useParams();
  const [scholarship, setScholarship] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchScholarship = async () => {
      try {
        // const response = await fetch(
        //   `http://localhost/web/react/www.jobsfair.info/api/api/scholarships/get_single_scholarships.php?id=${id}`
        // );

        const response = await fetch(
          `https://jobsfair.info/api/api/scholarships/get_single_scholarships.php?id=${id}`
        );

        const data = await response.json();

        if (data.success) {
          setScholarship(data.data);
        } else {
          setError(data.message);
        }
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchScholarship();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="scholarship-details">
      <Navbar />
      <div className="datails-container">
        <div className="side-panel1">
          {/* Optional content for the left panel */}
        </div>
        <div className="details-main-container">
          <div className="scholarship-content">
            <div className="scholarships-details-info">
              {scholarship && (
                <>
                  {scholarship.title && (
                    <h2 className="scholarship-title">{scholarship.title}</h2>
                  )}
                  {scholarship.application_deadline && (
                    <p>
                      <strong>Application Deadline:</strong>{" "}
                      {scholarship.application_deadline}
                    </p>
                  )}
                  {scholarship.description && <p>{scholarship.description}</p>}

                  {scholarship.provider_website && (
                    <p>
                      <strong>Provider Website:</strong>{" "}
                      <a
                        href={scholarship.provider_website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {scholarship.provider_website}
                      </a>
                    </p>
                  )}
                  {scholarship.location && (
                    <p>
                      <strong>Location:</strong> {scholarship.location}
                    </p>
                  )}
                  {scholarship.category && (
                    <p>
                      <strong>Category:</strong> {scholarship.category}
                    </p>
                  )}
                  {scholarship.university && (
                    <p>
                      <strong>University:</strong> {scholarship.university}
                    </p>
                  )}
                  {scholarship.institution && (
                    <p>
                      <strong>Institution:</strong> {scholarship.institution}
                    </p>
                  )}
                  {scholarship.study_in && (
                    <p>
                      <strong>Study In:</strong> {scholarship.study_in}
                    </p>
                  )}
                  {scholarship.level_of_study && (
                    <p>
                      <strong>Level of Study:</strong>{" "}
                      {scholarship.level_of_study}
                    </p>
                  )}
                  {scholarship.amount && (
                    <p>
                      <strong>Amount:</strong> {scholarship.amount}
                    </p>
                  )}
                  {scholarship.eligibility_criteria && (
                    <p>
                      <strong>Eligibility Criteria:</strong>{" "}
                      {scholarship.eligibility_criteria}
                    </p>
                  )}
                  {scholarship.status && (
                    <p>
                      <strong>Status:</strong> {scholarship.status}
                    </p>
                  )}
                  {scholarship.created_at && (
                    <p>
                      <strong>Created At:</strong>{" "}
                      {new Date(scholarship.created_at).toLocaleDateString()}
                    </p>
                  )}
                  {scholarship.updated_at && (
                    <p>
                      <strong>Updated At:</strong>{" "}
                      {new Date(scholarship.updated_at).toLocaleDateString()}
                    </p>
                  )}
                  {scholarship.provider_name && (
                    <p>
                      <strong>Provider Name:</strong>{" "}
                      {scholarship.provider_name}
                    </p>
                  )}
                  {scholarship.apply_url && (
                    <p>
                      <strong>Apply URL:</strong>{" "}
                      <a
                        href={scholarship.apply_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {scholarship.apply_url}
                      </a>
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="side-panel2">
          {/* Optional content for the right panel */}
        </div>
      </div>
    </div>
  );
};

export default ScholarshipDetails;
