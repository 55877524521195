import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "./AuthContext";
import "../css1/LoginUser.css";
import Navbar from "../../components/inc/Navbar";

function LoginUser() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth(); // Get login function from AuthContext

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!email || !password) {
      setError("Both fields are required.");
      return;
    }

      // Log the email and password to check if they're captured correctly
      //console.log("Email:", email);
      //console.log("Password:", password);

    try {
      const success = await login(email, password); // Call login function from context
      if (success) {
        setError("");
        navigate('/back/dashboard');
      } else {
        setError("Invalid email or password.");
      }
    } catch (error) {
      //console.error('Login error:', error);
      setError("An error occurred. Please try again.");
    }
  };

  const handleOnInputChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  return (
    <div>
      <Navbar />
    
    <div className="user-login-page">
      <div className="login-header">
        <h2>Login</h2>
      </div>
      <div className="user-login-form">
        <form onSubmit={handleOnSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              value={email}
              onChange={handleOnInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              className="form-control"
              value={password}
              onChange={handleOnInputChange}
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" name="btn_submit" className="btn btn-primary">
            Login
          </button>
        </form>
      </div>
    </div>
    </div>
  );
}

export default LoginUser;
