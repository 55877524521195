import React from 'react';
import Sidebar from './SidebarMenu';
import './css1/Dashboard.css';
import Header from './Header';


function Dashboard() {

  

  return (
    <div>
     <Header />
    
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-content">
        <h1>Dashboard</h1>
        <div className="dashboard-cards">
          <div className="card">
            <h3>Total Companies</h3>
            <p className="card-value">120</p>
          </div>
          <div className="card">
            <h3>Total Jobs</h3>
            <p className="card-value">345</p>
          </div>
          <div className="card">
            <h3>New Applications</h3>
            <p className="card-value">45</p>
          </div>
        </div>
        <div className="recent-activity">
          <h2>Recent Activity</h2>
          <ul>
            <li>Company "Tech Corp" added a new job listing.</li>
            <li>Job "Software Engineer" updated by "Innovate Ltd".</li>
            <li>New application received for "Project Manager" position.</li>
          </ul>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Dashboard;
