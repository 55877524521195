import React from "react";
// import "../../css/searchForm.css";
import "../../css/search-form-container.css";


function SearchForm() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const searchQuery = event.target.elements.search.value;
    console.log("Search query:", searchQuery);
  };
  return (

    <div>
            <div className="searchform-container">
                <div className="search-side-panel1">
                    {/* <h4>Refine the List with Filters</h4> */}
                    <div className="filter-options">
                       {/* <p>Filter options will go here </p> */}
                    </div>
                </div>
                
                <div className="search-main-form">
                    {/* <div className="jobs-container"> */}
                        <div className="searchform-info">
                            <form onSubmit={handleSubmit} className="searcForm">
                              <div className="selectCategory">
                                <select name="category">
                                  <option value="">ALL</option>
                                  <option value="it">IT</option>
                                  <option value="marketing">Marketing</option>
                                  <option value="finance">Finance</option>
                                </select>
                              </div>

                              <div className="inputSearch">
                                <input
                                  type="text"
                                  name="search"
                                  placeholder="Search for a job"
                                />
                              </div>

                              <div className="searchButton">
                                <button type="submit">Search</button>
                              </div>
                            </form>
                       </div>
                    {/* </div> */}
                </div>
                
                <div className="search-side-panel2">
                    {/* <h1>Additional Information</h1> */}
                    <div className="additional-info">
                    {/* <p>Additional information or ads can go here </p> */}
                    </div>
                </div>
            </div>

  
    </div>
  );
}

export default SearchForm;
