import React, { useState, useEffect } from "react";
import Sidebar from "../SidebarMenu";
import Header from "../Header";
import "../css1/AddJob.css";

// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";

const AddJob = () => {
  const [formData, setFormData] = useState({
    company_id: "",
    title: "",
    updatedAt: "",
    deadline: "",
    description: "",
    requirement: "",
    experiences: "",
    responsibility: "",
    qualification: "",

    applyUrl: "",
    category: "",
    department: "",
    location: "",
  });

  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
               // For Server API
         const response = await fetch(`https://jobsfair.info/api/api/list_companies.php` );

       // const response = await fetch(`http://localhost/web/react/www.jobsfair.info/api/api/list_companies.php` );
        if (!response.ok) {
          throw new Error("Failed to fetch companies");
        }
        const data = await response.json();
        setCompanies(data);
      } catch (error) {
       // console.error("Error fetching companies:", error);
        setError("An error occurred while fetching the data");
      }
    };

    fetchCompanies();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

    // Define focus and blur handlers
    const handleFocus = (event) => {
      event.target.style.borderColor = '#007BFF'; // Blue border on focus
      event.target.style.boxShadow = '0 0 8px rgba(0, 123, 255, 0.5)'; // Subtle blue glow
      event.target.style.transform = 'scale(1.02)'; // Slightly scale up on focus
    };

    const handleBlur = (event) => {
      event.target.style.borderColor = '#ccc'; // Gray border on blur
      event.target.style.boxShadow = '0 0 4px rgba(0, 0, 0, 0.2)'; // Subtle shadow on blur
      event.target.style.transform = 'scale(1)'; // Reset scale to normal
    };

      // Define the styles for textareas
  const textareaStyle = {
    width: '100%',
    height: '250px',
    fontSize: '16px',
    padding: '12px',
    borderRadius: '8px',
    border: '2px solid #ccc',  // Slightly thicker border
    outline: 'none',
    resize: 'vertical',
    backgroundColor: '#f9f9f9',
    color: '#333',
    transition: 'border-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease', // Smooth transitions
  };

  // Define the styles for textareas
  const forOtherStyle = {

          fontSize: '16px',
          padding: '12px',
          borderRadius: '8px',
          border: '2px solid #ccc',  // Slightly thicker border
          outline: 'none',
          resize: 'vertical',
          backgroundColor: '#f9f9f9',
          color: '#333',
          transition: 'border-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease', // Smooth transitions
        };




  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('Form data:', formData);

    // Uncomment and configure this section when ready to submit data
    try {

                     // For Server API
         const response = await fetch(`https://jobsfair.info/api/api/add_new_job.php`,{
        
      // const response = await fetch(`http://localhost/web/react/www.jobsfair.info/api/api/add_new_job.php`,{
     
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();

    //  console.log("result.success: " + result.success);
     // console.log("result.message: " + result.message);

      if (result.success) {
        setSuccess("Job added successfully!");
        alert("Job added successfully");
      } else {
        setError(result.message || "An error occurred");
        throw new Error("Failed to add job");
      }

      // if (response.ok) {
      //   setSuccess('Company added successfully!');
      //   alert('Job added successfully');
      // } else {
      //   setError(result.message || 'An error occurred');
      //   throw new Error('Failed to add job');
      // }
    } catch (error) {
      alert( "Error submitting job: A job with the same title, company, updated date, and deadline already exists.");
      //console.error("Error submitting job:", error);
    }

    // Reset error if no issues
    //setError(null);

    // Placeholder for form submission logic
    //console.log("Form data:", formData);
    // alert("Form submitted (server code not implemented)");
  };

  return (
    <div>
      <Header />
      <div className="add-job-page">
        <Sidebar />
        <div className="add-job-container">
          <h1>Add New Job</h1>
          <div className="session-message">
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}
          </div>

          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="company_id">Company:</label>
                <select
                  id="company_id"
                  name="company_id"
                  value={formData.company_id}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  style={forOtherStyle}
                  required
                >
                  <option value="" disabled>
                    Select a company
                  </option>
                  {companies.map((company) => (
                    <option 
                    key={company.id} 
                    value={company.id}
                 
                    >
                      {company.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="title">Jobs Title:</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  style={forOtherStyle}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="updatedAt">Updated At(Published):</label>
                <input
                  type="date"
                  id="updatedAt"
                  name="updatedAt"
                  value={formData.updatedAt}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  style={forOtherStyle}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="deadline">Deadline:</label>
                <input
                  type="date"
                  id="deadline"
                  name="deadline"
                  value={formData.deadline}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  style={forOtherStyle}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="description">Description:</label>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  required
                  style={textareaStyle}
                />
            
              </div>
              <div className="form-group">
                <label htmlFor="category">Category:</label>
                <input
                  type="text"
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  style={forOtherStyle}
                  
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="requirement">Requirement:</label>
                <textarea
                  id="requirement"
                  name="requirement"
                  value={formData.requirement}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  style={textareaStyle}
                  required
                />
      
              </div>
              <div className="form-group">
                <label htmlFor="experiences">Experiences:</label>
                <textarea
                  id="experiences"
                  name="experiences"
                  value={formData.experiences}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  style={forOtherStyle}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="responsibility">Responsibility:</label>
                <textarea
                  id="responsibility"
                  name="responsibility"
                  value={formData.responsibility}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  style={textareaStyle}
                />

         
              </div>
              <div className="form-group">
                <label htmlFor="qualification">Qualification:</label>
                <textarea
                  id="qualification"
                  name="qualification"
                  value={formData.qualification}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  style={forOtherStyle}
                />
              </div>
            </div>
            <div className="form-row">

              <div className="form-group">
                <label htmlFor="department">Department:</label>
                <input
                  type="text"
                  id="department"
                  name="department"
                  value={formData.department}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  style={forOtherStyle}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="location">Job Location:</label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  style={forOtherStyle}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group full-width">
                <label htmlFor="applyUrl">Apply URL:</label>
                <input
                  type="text"
                  id="applyUrl"
                  name="applyUrl"
                  value={formData.applyUrl}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  style={forOtherStyle}
                  
                />
              </div>
            </div>
            <button type="submit" className="submit-btn">
              Add New Job
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddJob;
