import React, { useState, useEffect } from "react";
import SidebarMenu from "../SidebarMenu";
import "../css1/Jobs.css";
import { Link } from "react-router-dom";
import Header from "../Header";

function Jobs() {
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        // For Server API
        const response = await fetch(
          `https://jobsfair.info/api/api/lists_of_jobs.php`
        );

        // const response = await fetch( `http://localhost/web/react/www.jobsfair.info/api/api/lists_of_jobs.php` );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setCompanies(data.data);
      } catch (error) {
        //console.error("Error fetching jobs:", error);
        setError("Failed to load jobs. Please try again later.");
      }
    };

    fetchJobs();
  }, []);

  return (
    <div className="jobs-list-page">
      <Header />
      <div className="back-jobs-container">
        <SidebarMenu />

        <div className="jobs-content">
          <header className="jobs-header">
            <h1>Manage Jobs</h1>
            <Link to="/back/jobs/add-jobs" className="add-job-btn">
              Add New Job
            </Link>
          </header>

          <div className="table-container">
            {error && <p className="error-message">{error}</p>}
            {companies.length > 0 ? (
              <table className="job-list">
                <thead>
                  <tr>
                    <th>Job ID</th>
                    <th>Company</th>

                    <th>Title</th>

                    <th>job_description </th>
                    <th>requirement </th>
                    <th>experiences </th>

                    <th>applyUrl </th>

                    <th>Updated</th>
                    <th>Deadline</th>

                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {companies.map((company) =>
                    company.jobs.map((job) => (
                      <tr key={job.job_id}>
                        <td>{job.job_id}</td>
                        <td>{company.company_name}</td>

                        <td>{job.title}</td>

                        <td>{job.description}</td>
                        <td>{job.requirement}</td>
                        <td>{job.experiences}</td>

                        <td>{job.applyUrl}</td>

                        <td>{new Date(job.updatedAt).toLocaleDateString()}</td>
                        <td>{new Date(job.deadline).toLocaleDateString()}</td>
                        <td>
                          <Link
                            to={`/back/jobs/edit-job/${job.job_id}`}
                            className="edit-link"
                          >
                            Edit
                          </Link>
                          {" | "}
                          <Link
                            to={`/back/jobs/delete-job/${job.job_id}`}
                            className="delete-link"
                          >
                            Delete
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            ) : (
              <p className="no-jobs-message">
                No jobs available. Please add a new job.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jobs;
