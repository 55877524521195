import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../css/Navbar.css";
import logo from "../../assets/logo.ico";
// import logo from "../assets/logo.png";

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <header>
      <div className="main-menus">
        <div className="left-nav">
          <p></p>
        </div>
        <div className="main-nav">
          <nav className="navnav">
            <div className="logowrapper">
              <img src={logo} alt="Logo" />
              <h4>
                <Link to="/">Jobsfair.info </Link>
              </h4>
            </div>

            <ul className={`navigations ${isMobileMenuOpen ? "active" : ""}`}>
              <li className={isActive("/") ? "active" : ""}>
                <Link to="/">Home</Link>
              </li>
              <li
                className={isActive("/pages/landing/aboutus") ? "active" : ""}
              >
                <Link to="/pages/landing/aboutus">Us</Link>
              </li>
              <li className={isActive("/pages/auth/jobs") ? "active" : ""}>
                <Link to="/pages/auth/register">Jobs</Link>
              </li>
              <li className={isActive("/pages/auth/training") ? "active" : ""}>
                <Link to="/pages/auth/register">Training</Link>
              </li>
              <li className={isActive("/pages/scholarships") ? "active" : ""}>
                <Link to="/pages/scholarships">Scholarships</Link>
              </li>
              <li className={isActive("/pages/auths/login") ? "active" : ""}>
                <Link to="/pages/auths/login">Login</Link>
              </li>
            </ul>

            <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
              &#9776;
            </button>
          </nav>
        </div>
        <div className="right-nav">
          <p></p>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
